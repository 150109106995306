module.exports = {
  defaultTitle: 'Robert Jakubowski',
  logo: 'https://portfolio.smakosh.com/favicon/favicon-512.png',
  author: 'Robert Jakubowski',
  url: 'https://robertjakubowski.pl',
  legalName: 'Robert Jakubowski',
  defaultDescription: 'Robert Jakubowski, Frontend Developer z Bydgoszczy',
  googleAnalyticsID: '',
  themeColor: '#6b63ff',
  backgroundColor: '#6b63ff',
  social: {
    facebook: '',
    twitter: '',
  },
  address: {
    city: 'City',
    region: 'Region',
    country: 'Country',
    zipCode: 'ZipCode',
  },
  contact: {
    email: 'email',
    phone: 'phone number',
  },
  foundingDate: '2016',
};
